import { Nav, Navbar, Form, Container } from 'react-bootstrap';
import { Button } from '@aws-amplify/ui-react';

const ProjectHeader = (props) => {
    return (
        <Navbar fixed='top' bg='dark' variant='dark'>
            <Container>
                <Navbar.Brand href='#'>ES HCLS Demo Portal</Navbar.Brand>
                <Navbar.Toggle />
                { props.user && props.user.username &&
                    <Navbar.Collapse className="justify-content-end">
                        <Form className="d-flex me-4">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                                size='sm'
                            />
                            <Button size='small' variation='primary'>Search</Button>
                        </Form>
                        <Nav className='align-items-center'>
                            <Nav.Item>
                                <Navbar.Text>Hello {props.user.attributes.given_name}</Navbar.Text>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link>
                                    <Button size='small' variation='primary' onClick={props.signOut}>Sign out</Button>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                }
            </Container>
        </Navbar>
    )
}

export default ProjectHeader;