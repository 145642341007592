/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:75ded224-8636-4129-abbb-16d92e3a4be8",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Sm6HYI2h5",
    "aws_user_pools_web_client_id": "5ed3q5n0o6q5h2qmmdajhi6j93",
    "oauth": {
        "domain": "es-hcsl-demo-portal.auth.eu-west-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "demoportalapi",
            "endpoint": "https://hyywnx7ky0.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_mobile_analytics_app_id": "3f5d834de1844ecdaa7491e83fcbc28a",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3f5d834de1844ecdaa7491e83fcbc28a",
            "region": "eu-west-1"
        }
    }
};


export default awsmobile;
