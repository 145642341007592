import { Container } from "react-bootstrap"

const ProjectFooter = () => (
    <footer className="footer fixed-bottom bg-dark text-white text-center d-flex align-items-center">
        <Container>
            <span>Spain Healthcare and Life Science Team - {new Date().getFullYear()}</span>
        </Container>
    </footer>
);

export default ProjectFooter;