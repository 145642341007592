import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Badge } from "@aws-amplify/ui-react";
import { Buffer } from 'buffer';

import { API } from "aws-amplify";

import LinkButton from "./LinkButton";

const prettifyRepoName = (uglyName) => {
    const words = uglyName.replaceAll("-", " ").split(" ");
    return words.map((w)=> w.charAt(0).toUpperCase() + w.slice(1)).join(" ");
} 

const ProjectCard = (props) => {
    const {
        project,
        region
    } = props;

    const statusColorMap = {
        running: "success",
        stopped: "error",
        unknown: "warning",
        serverless: "info"
    }

    const [projectData, setProjectData] = useState({});
    const [status, setStatus] = useState('unknown');

    const runURL = `https://${project.apiId}.execute-api.${region}.amazonaws.com/Prod/run/`;
    const stopURL = `https://${project.apiId}.execute-api.${region}.amazonaws.com/Prod/stop/`;

    useEffect(() => {
        const fetchProjectData = async () => {
            const data = await API.get('demoportalapi', `/project/${project.repositoryName}`);

            // Render Image
            const thumbnail = document.getElementById(`${project.repositoryName}-thumb`);
            if (data.thumbnail) {
                let buffer = new Buffer.from(data.thumbnail).toString('base64');
                var dataPNG = "data:image/png;base64," + buffer;
                thumbnail.src = dataPNG;
            } else {
                thumbnail.src = "aws-thumb.png";
            }

            // Get project status          
            const statusURL = `https://${project.apiId}.execute-api.${region}.amazonaws.com/Prod/status/`;
            fetch(statusURL)
                .then(response => response.json())
                .then(data => {
                    setStatus(data.message.toLowerCase());
                });

            // Send data to state
            setProjectData(data);
        }

        fetchProjectData().catch(console.error);
    }, [project.repositoryName, project.apiId, region, status])

    return (
        <Card className="project-card text-center">
            { projectData && 
            <>
                <div className="badges-container">
                    <Badge variation={statusColorMap[status]} size='small'>
                        {status.toUpperCase()}
                    </Badge>
                </div>
                <div className="img-container">
                    <Card.Img id={ `${project.repositoryName}-thumb` } variant="top" className="img"/>
                </div>
                <Card.Body>
                    <a 
                        className="project-link" 
                        target="_blank" 
                        rel="noreferrer" 
                        href={projectData.homepage} 
                        title={project.repositoryName}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="visit"
                        data-amplify-analytics-attrs={`repository:${project.repositoryName}`}
                    >
                        <Card.Title>{ prettifyRepoName(project.repositoryName) }</Card.Title>
                        <Card.Text>{ projectData.repositoryDescription }</Card.Text>
                    </a>
                </Card.Body>
                <Card.Footer>
                    { status !== "serverless" && 
                        <>
                        { status === "running" &&
                            <LinkButton 
                                variant="warning"
                                url={stopURL} 
                                text="Stop"
                                setStatus={setStatus}
                                {... props}
                                />
                        }
                        { status === "stopped" &&
                            <LinkButton 
                                variant="primary"
                                url={runURL} 
                                text="Run"
                                setStatus={setStatus}
                                {... props} />
                        }
                        </>
                    }
                </Card.Footer>
            </>
            }
        </Card>
    )
}

export default ProjectCard;