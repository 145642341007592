import { React, useEffect, useState } from 'react';
import { Amplify, API, Auth, Hub, Analytics } from 'aws-amplify';

import './App.scss';
import '@aws-amplify/ui-react/styles.css';

import awsConfig from './aws-exports';

import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import ProjectCard from './components/ProjectCard';
import ProjectHeader from './components/ProjectHeader';
import ProjectFooter from './components/ProjectFooter';
import Alerts from './components/Alerts';
import { Button, Card, Flex } from '@aws-amplify/ui-react';

// Extra configuration for Cognito
awsConfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsConfig.oauth.redirectSignOut = `${window.location.origin}/`;
awsConfig.oauth.scope = ["email", "openid", "aws.cognito.signin.user.admin", "profile"];
awsConfig.oauth.responseType = "code";
const federatedIdName = "Midway";
Amplify.configure(awsConfig);

const App = () => {
  // Session control 
  const [user, setUser] = useState(null);

  const [projects, setProjects] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('Success');

  const setErrorOnAlert = (err) => {
    console.error(err);
    setToastMessage(err.message);
    setToastVariant("danger");
    setShowToast(true);
  }

  const getUser = () => {
    return  Auth.currentAuthenticatedUser()
      .then(user => user)
      .catch(err => console.error(err))
  }

  const signOut = () => {
    return Auth.signOut()
      .then(setUser(null))
      .catch(err => {
        setErrorOnAlert(err);
      })
  }

  const trackUserId = async (user) => {
    try {
      Analytics.updateEndpoint({
        address: user.attributes.email,
        channelType: 'EMAIL',
        optOut: 'NONE',
        userId: user.attributes.sub,
        ...user.attributes,
      });
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getUser().then(user => {
      setUser(user);
      trackUserId(user);
    });

    Hub.listen("auth", ({payload: {event, data}}) => {
      console.log(event);
      switch (event) {
        case "cognitoHostedUI":
          setUser("grating...");
          getUser().then(user => {
            setUser(user);
            trackUserId(user);
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          setErrorOnAlert(data);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => { 
    const fetchProjects = async () => {
      const data = await API.get('demoportalapi', '/project');
      setProjects(data);
    }

    if (user) {
      fetchProjects()
        .catch(console.error)
    }
  }, [user]);

  return (
    <Container className='main-container'>
      <ProjectHeader 
        user={user}
        signOut={signOut}
      />
      <Container className='body-container'>
        <Alerts
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
          toastVariant={toastVariant}
        />
        { user ? (
          <Row>
            {
              projects.map((item) => (
                <Col key={item.repositoryId}>
                  <ProjectCard 
                    project={item} 
                    region={awsConfig.aws_project_region}
                    setShowToast={setShowToast}
                    setToastMessage={setToastMessage}
                    setToastVariant={setToastVariant}
                  />
                </Col>
              ))
            }
          </Row>
        ) : (
          <Flex justifyContent="center">
            <Card variation='outlined' className='text-center mt-5 w-50 p-5'>
              <p>Welcome to ES HCLS Demo Portal</p>
              <Button
                variation="primary"
                onClick={() => Auth.federatedSignIn({provider: federatedIdName})}
              >
                Midway Sign In
              </Button>
            </Card>
          </Flex>
        )}
      </Container>
      <ProjectFooter /> 
    </Container>
  );
}

export default App;
