import React from "react";
import { Toast } from "react-bootstrap";

const Alerts = (props) => {
    const {
        showToast,
        setShowToast,
        toastVariant,
        toastMessage,
    } = props;
    return (
        <div className="toast-container position-absolute p-3 top-0 end-0">
            <Toast 
                show={showToast} 
                onClose={() => setShowToast(false)}
                delay={5000} 
                autohide
                bg={toastVariant}
            >
                <Toast.Header className="justify-content-between">
                    <strong>ES HCLS Demo Portal</strong>
                </Toast.Header>
                <Toast.Body className="text-white">
                    <strong>{ toastMessage }</strong>
                </Toast.Body>
            </Toast>
        </div>
    );
}

export default Alerts;