import React, { useState } from "react";
import { Button } from "@aws-amplify/ui-react";

const LinkButton = (props) => {
    const {
        variant,
        text,
        url,
        setShowToast,
        setToastMessage,
        setToastVariant,
        setStatus
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async() => {
        try {
            setIsLoading(true);
            const response = await fetch(url);
            const data = await response.json();

            setIsLoading(false);
            setToastMessage(data.message);
            if (response.ok) {
                setToastVariant('success');
            } else {
                setToastVariant('danger');
            }
            setShowToast(true);
            setStatus('Loading...')
        } catch (error) {
            setIsLoading(false);
            setToastMessage(error.message);
            setToastVariant('danger');
            setShowToast(true);
            setStatus('Loading...')
        }
    } 

    return (
        <>
            <Button 
                isLoading={isLoading}
                variation={variant} 
                size="small" 
                loadingText="Loading..."
                onClick={handleClick} 
                className="w-100"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name={text.toLowerCase}
                data-amplify-analytics-attrs={`url:${url}`}
                >
                { text }
            </Button>
        </>
    )
}

export default LinkButton;